import Vue from 'vue'
import ContentContainer from './layout/ContentContainer.vue'
import ContentRow from './layout/ContentRow.vue'
import Button from './ui/Button.vue'
// import LinkButton from './ui/LinkButton.vue'
import Titles from './typography/Titles.vue'

Vue.component('ContentContainer', ContentContainer)
Vue.component('ContentRow', ContentRow)
Vue.component('Button', Button)
Vue.component('LinkButton', () => import('./ui/LinkButton.vue'))
Vue.component('Titles', Titles)
