








import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Titles extends Mixins(Vue) {
  @Prop({ default: false })
  private readonly isCentered!: boolean
}
