import { Service } from '../../IService'
import ServiceType from '../../ServiceTypes'
import HttpError from '../HttpError'
import IHttpClient from '../IHttpClient'
import IHttpRequest from '../IHttpRequest'

class FetchClient implements Service, IHttpClient {
  Type: ServiceType = ServiceType.HttpClient
  Id = 'FetchClient'

  private static BuildFetchRequest (req: IHttpRequest): Request {
    const fetchReq = new Request(req.BuildUrl(), {
      method: req.Method.toString(),
      mode: 'cors',
      cache: req.CacheMode,
      headers: req.Headers,
      body: req.Body
    })

    return fetchReq
  }

  Fetch (req: IHttpRequest): Promise<Response> {
    const fetchReq = FetchClient.BuildFetchRequest(req)

    return window.fetch(fetchReq)
  }

  Request<TResponse> (req: IHttpRequest): Promise<TResponse | null> {
    return this.Fetch(req).then(res => {
      if (res.ok) {
        if (res.status === 204) return Promise.resolve(null)

        return res
          .json()
          .then(data => {
            return Promise.resolve(data as TResponse)
          })
          .catch(() => {
            return Promise.reject(Error)
          })
      } else {
        switch (res.status) {
          case 400: {
            return res.json().then(
              // () => Promise.reject(Error)
              data =>
              // eslint-disable-next-line prefer-promise-reject-errors
                Promise.reject({ Status: 400, ModelState: data } as HttpError)
            )
          }
        }
        // return Promise.reject(Error)
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ Status: res.status } as HttpError)
      }
    })
  }
}

export default FetchClient
