












import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'
import Card from './BaseCard.vue'

@Component({
  components: {
    Card
  }
})
export default class CalloutCard extends Mixins(Vue) {
  @Prop({ required: true })
  private readonly icon!: string

  @Prop({ default: 'icon-with-heading' })
  private readonly layout!: string

  @Prop({ default: false })
  private readonly isWide!: boolean;
}
