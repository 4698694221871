




import { Vue, Mixins, Prop, Component } from 'vue-property-decorator'

@Component
export default class MaterialIcon extends Mixins(Vue) {
  @Prop()
  private readonly icon!: string
}
