




















































import { Mixins, Component, Vue, Prop } from 'vue-property-decorator'
import NavigationLink from './NavigationLink.vue'

@Component({
  components: {
    NavigationLink
  }
})
export default class Navigation extends Mixins(Vue) {
  @Prop()
  private readonly mobileNavActive!: boolean
}
