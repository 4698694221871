
























































import { Mixins, Vue, Component } from 'vue-property-decorator'
import Card from './cards/BaseCard.vue'

@Component({
  components: {
    Card
  }
})
export default class ImageGrid extends Mixins(Vue) {}
