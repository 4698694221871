








import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Feature extends Mixins(Vue) {
  @Prop({ required: true })
  private readonly icon!: string
}
