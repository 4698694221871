import { GetBlogModel } from '@/app_code/models/GetBlogModel'
import { PagedResultModel } from '@/app_code/models/PagedResultModel'
import APIBase from './APIBase'

class BlogAPI extends APIBase {
  GetBlog (id: string): Promise<GetBlogModel | null> {
    return this.GET({
      Path: `/api/site/site_content/blogs/${id}`
    })
  }

  GetBlogs (page: number): Promise<PagedResultModel<GetBlogModel[]> | null> {
    return this.GET({
      Path: '/api/site/site_content/blogs',
      QueryParams: {
        page: page.toString()
      }
    })
  }
}

export { BlogAPI }
