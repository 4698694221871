import AppConfig from './app_code/config/AppConfig'
import { LogLevel } from './app_code/debug/Logger'
import HttpProviderType from './app_code/services/httpclient/HttpProviderType'

export default {
  API: {
    Base: 'https://my.prickly2sweet.com.au',
    Base1: 'https://localhost:44383'
  },
  Logging: {
    LogLevel: LogLevel.Debug
  },
  Http: {
    Provider: HttpProviderType.Fetch
  }
} as AppConfig
