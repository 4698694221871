import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
// CSS
import './assets/css/reset.css'
import './assets/css/config.css'
import './assets/css/layout.css'
import './assets/fonts/fonts.css'
// Register any global controls
import './components/global'

import AppConfig from './Config'

import Bootstrapper from './app_code/Bootstrapping/Bootstrapper'

Vue.config.productionTip = false

console.log('%cAssessment Software', 'color: #000;')
console.log('https://prickly2sweet.com.au/')

Bootstrapper.Initialise(AppConfig).then(() => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
})
