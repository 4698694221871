import { GetResourceModel } from '@/app_code/models/GetResourceModel'
import { PagedResultModel } from '@/app_code/models/PagedResultModel'
import APIBase from './APIBase'

class ResourceAPI extends APIBase {
  GetResource (id: string): Promise<GetResourceModel | null> {
    return this.GET({
      Path: `/api/site/site_content/resources/${id}`
    })
  }

  GetResources (
    page: number
  ): Promise<PagedResultModel<GetResourceModel[]> | null> {
    return this.GET({
      Path: '/api/site/site_content/resources',
      QueryParams: {
        page: page.toString()
      }
    })
  }
}

export { ResourceAPI }
