import { HeadOptions, RouteMeta, RouteMetaItem } from '.'
import { Service } from '../IService'
import ServiceType from '../ServiceTypes'

interface HeadTagCollecion {
  title: HTMLMetaElement
  url: HTMLMetaElement
  description: HTMLMetaElement
  image: HTMLMetaElement
}

interface HeadTags {
  description: HTMLMetaElement
  canonical: HTMLLinkElement
  openGraph: HeadTagCollecion
  twitterCard: HeadTagCollecion
}

class HeadService implements Service {
  Type: ServiceType = ServiceType.Head
  Id = 'HeadService'

  Options: HeadOptions
  DefaultMeta: RouteMeta
  Tags: HeadTags
  CustomTags: HTMLElement[] = []

  constructor (options: HeadOptions) {
    this.Options = options

    const description = options.meta.default.meta?.find(
      itm => itm.name === 'description'
    )

    this.DefaultMeta = {
      title: options.meta.default.title || '',
      description: description?.content || '',
      image: options.meta.default.image,
      custom: options.meta.default.meta
        ?.filter(m => m.name !== 'description')
        .map(m => {
          const attrs: Record<string, string> = {}

          if (m.content) attrs.content = m.content
          if (m.href) attrs.href = m.href
          if (m.property) attrs.property = m.property

          return {
            id: m.name,
            tag: m.tag || 'meta',
            attributes: attrs
          } as RouteMetaItem
        })
    }

    this.Tags = {
      description: this.CreateTag<HTMLMetaElement>('meta', {
        name: 'description'
      }),
      canonical: this.CreateTag<HTMLLinkElement>('link', { rel: 'canonical' }),
      openGraph: {
        title: this.CreateTag<HTMLMetaElement>('meta', {
          property: 'og:title'
        }),
        url: this.CreateTag<HTMLMetaElement>('meta', { property: 'og:url' }),
        description: this.CreateTag<HTMLMetaElement>('meta', {
          property: 'og:description'
        }),
        image: this.CreateTag<HTMLMetaElement>('meta', { property: 'og:image' })
      },
      twitterCard: {
        title: this.CreateTag<HTMLMetaElement>('meta', {
          property: 'twitter:title'
        }),
        url: this.CreateTag<HTMLMetaElement>('meta', {
          property: 'twitter:url'
        }),
        description: this.CreateTag<HTMLMetaElement>('meta', {
          property: 'twitter:description'
        }),
        image: this.CreateTag<HTMLMetaElement>('meta', {
          property: 'twitter:image'
        })
      }
    }
  }

  CreateTag<TElem> (tagName: string, attributes: Record<string, string>): TElem {
    const elem: HTMLElement = document.createElement(tagName)

    Object.keys(attributes).forEach(k => {
      elem.setAttribute(k, attributes[k])
    })

    document.head.appendChild(elem)

    return (elem as unknown) as TElem
  }

  Update (meta: RouteMeta | null): void {
    if (meta === null) {
      meta = this.DefaultMeta
    }

    const title = `${meta.title}${this.Options.meta.title_delimiter}${this.Options.meta.site_name}`

    document.title = title
    this.Tags.openGraph.title.content = title
    this.Tags.twitterCard.title.content = title

    this.Tags.canonical.href = meta.url || window.location.href
    this.Tags.openGraph.url.content = meta.url || window.location.href
    this.Tags.twitterCard.url.content = meta.url || window.location.href

    this.Tags.description.content = meta.description
    this.Tags.openGraph.description.content = meta.description
    this.Tags.twitterCard.description.content = meta.description

    this.Tags.openGraph.image.content =
      meta.image || this.Options.meta.default.image || ''
    this.Tags.twitterCard.image.content =
      meta.image || this.Options.meta.default.image || ''

    if (this.CustomTags.length) {
      this.CustomTags.forEach(tag => {
        tag.remove()
      })

      this.CustomTags = []
    }

    if (meta.custom && meta.custom.length) {
      meta.custom.forEach(custom => {
        const tag = this.CreateTag<HTMLElement>(custom.tag, custom.attributes)
        this.CustomTags.push(tag)
      })
    }
  }

  UpdateWithStatic (path: string): void {
    const route = this.Options.meta.items.find(m => m.path === path)

    if (route) {
      const description = route.meta?.find(itm => itm.name === 'description')

      const routeCustom = route.meta
        ?.filter(m => m.name !== 'description')
        .map(m => {
          const attrs: Record<string, string> = {}

          if (m.content) attrs.content = m.content
          if (m.href) attrs.href = m.href
          if (m.property) attrs.property = m.property

          return {
            id: m.name,
            tag: m.tag || 'meta',
            attributes: attrs
          } as RouteMetaItem
        })

      const custom = [
        ...(this.DefaultMeta.custom || []),
        ...(routeCustom || [])
      ]

      this.Update({
        title: route.title,
        description: description?.content || this.DefaultMeta.description,
        image: route.image || this.DefaultMeta.image,
        custom: custom
      })
    } else {
      this.Update(null)
    }
  }
}

export { HeadService }
