

































import { Mixins, Vue, Component } from 'vue-property-decorator'
import Navigation from './Navigation.vue'
import MaterialIcon from '../ui/MaterialIcon.vue'

@Component({
  components: {
    Navigation,
    MaterialIcon
  }
})
export default class Header extends Mixins(Vue) {
  private isStuck = false

  private mobileNavActive = false

  mounted (): void {
    window.addEventListener('scroll', this.checkScroll, { passive: true })

    this.checkScroll()

    this.$router.afterEach(() => {
      this.mobileNavActive = false
    })
  }

  checkScroll (): void {
    this.isStuck = window.scrollY > 0
  }
}
