
























import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'
import IconLinkCard from './cards/IconLinkCard.vue'

@Component({
  components: {
    IconLinkCard
  }
})
export default class FiveStepsStep extends Mixins(Vue) {
  @Prop({ required: true })
  private readonly stepNumber!: number

  @Prop({ required: true })
  private readonly icon!: string

  @Prop({ default: false })
  private readonly wide!: boolean

  @Prop({ default: false })
  private readonly reversed!: boolean

  @Prop({ default: false })
  private readonly centered!: boolean

  @Prop({ default: false })
  private readonly external!: boolean

  @Prop({ required: true })
  private readonly to!: string
}
