


















import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'
import ContentContainer from './layout/ContentContainer.vue'

@Component({
  components: {
    ContentContainer
  }
})
export default class Topic extends Mixins(Vue) {
  @Prop({ default: 'center' })
  private readonly alignment!: string

  @Prop({ default: '1rem' })
  private readonly spacing!: string

  @Prop({ default: '0' })
  private readonly padding!: string

  @Prop()
  private readonly isReadable!: boolean

  get style (): string {
    return `padding: ${this.padding};`
  }
}
