import { RuleResultModel } from '@/app_code/models/RuleResultModel'
import APIBase from './APIBase'

class PaymentAPI extends APIBase {
  GetPricingCalculatorRules (): Promise<RuleResultModel[] | null> {
    return this.GET({
      Path: '/api/payment/pricing_calculator/rules'
    })
  }
}

export { PaymentAPI }
