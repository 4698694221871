import AppConfig from '../config/AppConfig'
import { Logger, LogLevel } from '../debug/Logger'
import { API, ServiceManager, FetchHttpClient, IHttpClient } from '../services'
import { HeadService } from '../services/meta/HeadService'
import HttpProviderType from '../services/httpclient/HttpProviderType'
import SiteMetadata from '../services/meta/meta.json'

class Bootstrapper {
  public static Config: AppConfig | null = null

  static Initialise (config: AppConfig): Promise<boolean> {
    Logger.Log(LogLevel.Debug, 'Bootstrapper', 'Beginning bootstrap process', config)

    this.Config = config

    return new Promise((resolve) => {
      Logger.SetLevel(config.Logging.LogLevel)

      this.InitServices()

      Logger.Log(LogLevel.Debug, 'Bootstrapper', 'Finished bootstrapping process')

      resolve(true)
    })
  }

  private static InitServices () {
    if (!this.Config) return

    // HTTP Client
    let httpClient: IHttpClient | null = null

    switch (this.Config.Http.Provider) {
      case HttpProviderType.Fetch:
        httpClient = new FetchHttpClient()
        break
    }

    if (httpClient === null) throw new Error('No HTTP Client Registered')

    ServiceManager.Register(httpClient)

    ServiceManager.Register(new API(httpClient))

    ServiceManager.Register(new HeadService({ meta: SiteMetadata }))
  }
}

export default Bootstrapper
