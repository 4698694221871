






import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'
import Card from './BaseCard.vue'

@Component({
  components: {
    Card
  }
})
export default class ImageCard extends Mixins(Vue) {
  @Prop({ required: true })
  private readonly source!: string

  get alt (): string {
    return this.source.substring(this.source.lastIndexOf('/') + 1)
  }
}
