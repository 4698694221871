



























































































































































import { Mixins, Vue, Component } from 'vue-property-decorator'
import CallToAction from './CallToAction.vue'

@Component({
  components: {
    CallToAction
  }
})
export default class Footer extends Mixins(Vue) {}
