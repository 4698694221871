import { Logger, LogLevel } from '../debug/Logger'
import { OnDemandService, Service } from './IService'
import ServiceFactory from './ServiceFactory'
import ServiceType from './ServiceTypes'

class ServiceManager {
  private static Services: Service[] = []
  private static Factories: ServiceFactory<OnDemandService>[] = []

  static RegisterFactory<TService extends OnDemandService> (type: ServiceType, factory: () => TService): void {
    this.Factories.push(new ServiceFactory<TService>(type, factory))

    Logger.Log(LogLevel.Debug, 'Service Manager', 'Registered service factory', type)
  }

  static Register (service: Service): void {
    this.Services.push(service)

    Logger.Log(LogLevel.Debug, 'Service Manager', 'Registered service', service)
  }

  static TryRegister (type: ServiceType, builder: () => Service): boolean {
    try {
      const service: Service = builder()

      ServiceManager.Register(service)

      return true
    } catch (ex) {
      Logger.Log(LogLevel.Err, 'Service Manager', 'Failed to register service', type, ex)

      return false
    }
  }

  private static TryProduce (type: ServiceType): Service | undefined {
    const factory = this.Factories.find((f) => f.Type === type)

    if (factory !== undefined) {
      const service = factory.GetService()

      this.Register(service)

      return service
    }

    return undefined
  }

  static Require<T extends Service> (type: ServiceType, id?: string): T {
    const service = this.Get<T>(type, id)

    if (service === null) throw new Error('Required service not found.')

    return service
  }

  static Get<T extends Service> (type: ServiceType, id?: string): T | null {
    const hasId = id !== undefined

    let service = this.Services.find(s => s.Type === type && (!hasId || s.Id === id))

    if (service === null) {
      service = this.TryProduce(type)

      // If we still don't have a service, return null
      if (service === null) return null
    }

    return service as T
  }

  static GetAll<T extends Service> (type: ServiceType): T[] {
    return this.Services.filter(s => s.Type === type).map(s => s as T)
  }
}

export default ServiceManager
