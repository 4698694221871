






import { Mixins, Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NavigationLink extends Mixins(Vue) {
  @Prop({ required: true })
  private readonly to!: string;

  @Prop({ default: false })
  private readonly external!: boolean;

  handleClicked (e: Event): void{
    e.preventDefault()

    if (this.external) {
      window.open(this.to, '_blank')
    } else if (this.$route.path !== this.to) {
      this.$router.push(this.to)
    }
  }

  get isActive (): boolean {
    return this.$route.path === this.to
  }
}
