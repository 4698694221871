






import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'
import LinkCard from './LinkCard.vue'

@Component({
  components: {
    LinkCard
  }
})
export default class ClassName extends Mixins(Vue) {
  @Prop({ required: true })
  private readonly icon!: 'string'

  @Prop({ default: false })
  private readonly external!: boolean

  @Prop({ required: true })
  private readonly to!: string
}
