














import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class SideBySide extends Mixins(Vue) {
  @Prop({ default: false })
  private readonly reversed!: boolean

  @Prop({ default: false })
  private readonly isColumnReversed!: boolean

  @Prop({ default: false })
  private readonly lessPadding!: boolean
}
