import Bootstrapper from '@/app_code/Bootstrapping/Bootstrapper'
import HttpRequest from '../httpclient/HttpRequest'
import IHttpClient from '../httpclient/IHttpClient'
import APIRequestInit from './APIRequestInit'

class APIBase {
  protected HttpClient: IHttpClient

  constructor (httpClient: IHttpClient) {
    this.HttpClient = httpClient
  }

  private GetUrl (path: string): string {
    if (path.startsWith('http')) return path

    // eslint-disable-next-line
    return `${Bootstrapper.Config!.API.Base}${path}`
  }

  protected GET<TResponse> (init: APIRequestInit): Promise<TResponse | null> {
    const req = HttpRequest.GET(
      this.GetUrl(init.Path),
      init.QueryParams,
      init.CacheMode
    )

    return this.HttpClient.Request<TResponse>(req)
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected POST<TResponse> (init: APIRequestInit): Promise<TResponse | null> {
    const req = HttpRequest.POST(this.GetUrl(init.Path), init.Body)

    return this.HttpClient.Request<TResponse>(req)
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected PATCH<TResponse> (init: APIRequestInit): Promise<TResponse | null> {
    const req = HttpRequest.PATCH(this.GetUrl(init.Path), init.Body)

    return this.HttpClient.Request<TResponse>(req)
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected PUT<TResponse> (init: APIRequestInit): Promise<TResponse | null> {
    const req = HttpRequest.PUT(this.GetUrl(init.Path), init.Body)

    return this.HttpClient.Request<TResponse>(req)
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected DELETE<TResponse> (init: APIRequestInit): Promise<TResponse | null> {
    const req = HttpRequest.DELETE(this.GetUrl(init.Path), init.Body)

    return this.HttpClient.Request<TResponse>(req)
  }
}

export default APIBase
