
















































































































































































































































































































































































import { View } from '@/app_code/services/meta/view'
import CallToAction from '../components/common/CallToAction.vue'
import { Component, Mixins } from 'vue-property-decorator'
import Card from '../components/cards/BaseCard.vue'
import CalloutCard from '../components/cards/CalloutCard.vue'
import ImageCard from '../components/cards/ImageCard.vue'
import Masthead from '../components/common/Masthead.vue'
import Dots from '../components/design/Dots.vue'
import Feature from '../components/Feature.vue'
import FiveSteps from '../components/FiveSteps.vue'
import ImageGrid from '../components/ImageGrid.vue'
import ContentContainer from '../components/layout/ContentContainer.vue'
import ContentRow from '../components/layout/ContentRow.vue'
import SideBySide from '../components/layout/SideBySide.vue'
import Topic from '../components/Topic.vue'

@Component({
  components: {
    ContentContainer,
    ContentRow,
    Masthead,
    Topic,
    SideBySide,
    ImageCard,
    Dots,
    CalloutCard,
    ImageGrid,
    Feature,
    Card,
    FiveSteps,
    CallToAction
  }
})
export default class Home extends Mixins(View) {}
