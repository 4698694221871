import CacheMode from './CacheMode'
import HttpRequestMethod from './HttpRequestMethod'
import IHttpRequest from './IHttpRequest'

class HttpRequest implements IHttpRequest {
  Method: HttpRequestMethod
  Url: string
  QueryParams: Record<string, string>
  Headers: Record<string, string>
  Body: string | FormData | null | undefined
  CacheMode: CacheMode

  constructor (
    method: HttpRequestMethod,
    url: string,
    queryParams?: Record<string, string>,
    headers?: Record<string, string>,
    body?: string | FormData | null | undefined,
    cacheMode?: CacheMode
  ) {
    this.Method = method
    this.Url = url
    this.QueryParams = queryParams ?? {}
    this.Headers = headers ?? {}
    this.Body = body
    this.CacheMode = cacheMode ?? CacheMode.Default
  }

  BuildUrl (): string {
    const keys = Object.keys(this.QueryParams)

    if (keys.length) {
      return `${this.Url}?${keys
        .map(
          k =>
            `${encodeURIComponent(k)}=${encodeURIComponent(
              this.QueryParams[k]
            )}`
        )
        .join('&')}`
    }

    return this.Url
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  static POST (url: string, body?: object): IHttpRequest {
    let req: HttpRequest | null = null

    if (body instanceof FormData) {
      req = new HttpRequest(
        HttpRequestMethod.POST,
        url,
        undefined,
        undefined,
        body,
        CacheMode.NoCache
      )
    } else if (body instanceof Blob) {
      const fd = new FormData()

      fd.append('file', body)

      req = new HttpRequest(
        HttpRequestMethod.POST,
        url,
        undefined,
        undefined,
        fd,
        CacheMode.NoCache
      )
    } else {
      req = new HttpRequest(
        HttpRequestMethod.POST,
        url,
        undefined,
        {
          'Content-Type': 'application/json'
        },
        body ? JSON.stringify(body) : undefined,
        CacheMode.NoCache
      )
    }

    return req
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  static PATCH (url: string, body?: object): IHttpRequest {
    let req: HttpRequest | null = null

    if (body instanceof FormData) {
      req = new HttpRequest(
        HttpRequestMethod.PATCH,
        url,
        undefined,
        undefined,
        body,
        CacheMode.NoCache
      )
    } else if (body instanceof Blob) {
      const fd = new FormData()

      fd.append('file', body)

      req = new HttpRequest(
        HttpRequestMethod.PATCH,
        url,
        undefined,
        undefined,
        fd,
        CacheMode.NoCache
      )
    } else {
      req = new HttpRequest(
        HttpRequestMethod.PATCH,
        url,
        undefined,
        {
          'Content-Type': 'application/json'
        },
        body ? JSON.stringify(body) : undefined,
        CacheMode.NoCache
      )
    }

    return req
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  static PUT (url: string, body?: object): IHttpRequest {
    let req: HttpRequest | null = null

    if (body instanceof FormData) {
      req = new HttpRequest(
        HttpRequestMethod.PUT,
        url,
        undefined,
        undefined,
        body,
        CacheMode.NoCache
      )
    } else if (body instanceof Blob) {
      const fd = new FormData()

      fd.append('file', body)

      req = new HttpRequest(
        HttpRequestMethod.PATCH,
        url,
        undefined,
        undefined,
        fd,
        CacheMode.NoCache
      )
    } else {
      req = new HttpRequest(
        HttpRequestMethod.PATCH,
        url,
        undefined,
        {
          'Content-Type': 'application/json'
        },
        body ? JSON.stringify(body) : undefined,
        CacheMode.NoCache
      )
    }

    return req
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  static DELETE (url: string, body?: object): IHttpRequest {
    let req: HttpRequest | null = null

    if (body instanceof FormData) {
      req = new HttpRequest(
        HttpRequestMethod.DELETE,
        url,
        undefined,
        undefined,
        body,
        CacheMode.NoCache
      )
    } else if (body instanceof Blob) {
      const fd = new FormData()

      fd.append('file', body)

      req = new HttpRequest(
        HttpRequestMethod.DELETE,
        url,
        undefined,
        undefined,
        fd,
        CacheMode.NoCache
      )
    } else if (body) {
      req = new HttpRequest(
        HttpRequestMethod.DELETE,
        url,
        undefined,
        {
          'Content-Type': 'application/json'
        },
        body ? JSON.stringify(body) : undefined,
        CacheMode.NoCache
      )
    } else {
      req = new HttpRequest(
        HttpRequestMethod.DELETE,
        url,
        undefined,
        undefined,
        undefined,
        CacheMode.NoCache
      )
    }

    return req
  }

  static GET (
    url: string,
    queryParams?: Record<string, string>,
    cacheMode?: CacheMode
  ): IHttpRequest {
    const req = new HttpRequest(
      HttpRequestMethod.GET,
      url,
      queryParams,
      undefined,
      undefined,
      cacheMode
    )
    return req
  }
}

export default HttpRequest
