






import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Card extends Mixins(Vue) {
  @Prop({ default: true })
  private readonly hasBoxShadow!: boolean

  @Prop({})
  private readonly hideOverflow!: boolean;
}
