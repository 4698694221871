import IHttpClient from '../httpclient/IHttpClient'
import { Service } from '../IService'
import ServiceType from '../ServiceTypes'
import { BlogAPI } from './BlogAPI'
import { UnitsAPI } from './UnitsAPI'
import { ResourceAPI } from './ResourceAPI'
import { PaymentAPI } from './PaymentAPI'
import SiteAPI from './SiteAPI'

class API implements Service {
  Type: ServiceType = ServiceType.API
  Id = 'API'

  constructor (httpClient: IHttpClient) {
    this.BlogAPI = new BlogAPI(httpClient)
    this.UnitsAPI = new UnitsAPI(httpClient)
    this.ResourceAPI = new ResourceAPI(httpClient)
    this.PaymentAPI = new PaymentAPI(httpClient)
    this.SiteAPI = new SiteAPI(httpClient)
  }

  public readonly BlogAPI: BlogAPI
  public readonly UnitsAPI: UnitsAPI
  public readonly ResourceAPI: ResourceAPI
  public readonly PaymentAPI: PaymentAPI
  public readonly SiteAPI: SiteAPI;
}

export { API }
