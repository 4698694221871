import SubmitContactFormModel from '@/app_code/models/SubmitContactFormModel'
import SubmitContactFormResponseModel from '@/app_code/models/SubmitContactFormResponseModel'
import APIBase from './APIBase'

export default class SiteAPI extends APIBase {
  SubmitContactForm (model: SubmitContactFormModel): Promise<SubmitContactFormResponseModel | null> {
    return this.POST({
      Path: '/api/site/contact',
      Body: model
    })
  }
}
