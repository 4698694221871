import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { HeadService } from '../HeadService'
import ServiceManager from '../../ServiceManager'
import ServiceType from '../../ServiceTypes'
import { RouteMeta } from '../'

interface ViewBase {
  head?(): RouteMeta | Promise<RouteMeta> | null
}

@Component
class ViewBase extends Vue {
  protected headService = ServiceManager.Require<HeadService>(ServiceType.Head)

  protected updateHead () {
    if ('head' in this) {
      // eslint-disable-next-line
      const result = this.head!.bind(this)()

      if (result instanceof Promise) {
        result.then((meta: RouteMeta | null) => {
          this.headService.Update(meta)
        })
      } else {
        this.headService.Update(result as RouteMeta | null)
      }
    } else {
      this.headService.UpdateWithStatic(this.$route.path)
    }
  }
}

@Component
class View extends ViewBase {
  public mounted (): void {
    requestAnimationFrame(() => {
      this.updateHead()
    })
  }
}

@Component
class AsyncView extends ViewBase {
  public mounted (): void {
    this.$on('updateView', () => {
      requestAnimationFrame(() => {
        this.updateHead()
      })
    })
  }
}

export { View, AsyncView }
