


















































































import { Mixins, Vue, Component } from 'vue-property-decorator'
import ContentContainer from '../components/layout/ContentContainer.vue'
import Topic from './Topic.vue'
import Step from './FiveStepsStep.vue'

@Component({
  components: {
    Topic,
    ContentContainer,
    Step
  }
})
export default class FiveSteps extends Mixins(Vue) {
}
