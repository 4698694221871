import GetUnitCategoryModel from '@/app_code/models/GetUnitCategoryModel'
import { GetUnitModel } from '@/app_code/models/GetUnitModel'
import { PagedResultModel } from '@/app_code/models/PagedResultModel'
import APIBase from './APIBase'

class UnitsAPI extends APIBase {
  GetUnits (page: number, search: string, categoryId: string): Promise<PagedResultModel<GetUnitModel[]> | null> {
    return this.GET({
      Path: '/api/units/public_list',
      QueryParams: {
        orderBy: 'UnitCodeAsc',
        search: search,
        categoryId: categoryId,
        page: page.toString()
      }
    })
  }

  GetCategories (countUnits: boolean): Promise<GetUnitCategoryModel[] | null> {
    return this.GET({
      Path: '/api/units/categories',
      QueryParams: {
        count: countUnits.toString()
      }
    })
  }
}

export { UnitsAPI }
