












import { Vue, Component } from 'vue-property-decorator'
import Dots from '../design/Dots.vue'

@Component({
  components: {
    Dots
  }
})
export default class CallToAction extends Vue {
  // mounted () {
  //   console.log(this.$route.meta)
  // }
}
