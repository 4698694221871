






import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Button extends Mixins(Vue) {
  @Prop({ default: false })
  private readonly disabled!: boolean
}
