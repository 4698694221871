













import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Dots extends Mixins(Vue) {
  @Prop({ default: 10 })
  private readonly width!: number

  @Prop({ default: 10 })
  private readonly height!: number

  @Prop({ default: '30px' })
  private readonly gap!: string

  @Prop({ default: '14px' })
  private readonly size!: string

  @Prop({ default: 1 })
  private readonly opacity!: number

  @Prop({ default: '#CAAF92' })
  private readonly color!: string

  get totalDots (): number {
    return this.height * this.width
  }

  get dotsStyle (): string {
    return `
    grid-template-columns: repeat(${this.width}, 1fr);
    grid-template-rows: repeat(${this.height}, 1fr);
    column-gap: ${this.gap}; 
    row-gap: ${this.gap};
    font-size: ${this.size};
    opacity: ${this.opacity};
    color: ${this.color};`
  }

  get iconStyle (): string {
    return `font-size: ${this.size};`
  }
}
