import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pricing',
    name: 'Pricing',
    meta: {
      ctaLinkOverride: 'https://meetings.hubspot.com/info6306/15-30-min-demo-of-valid8ed'
    },
    component: () => import('../views/Pricing.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/faq',
    name: 'Frequenty Asked Questions',
    component: () => import('../views/FAQ.vue')
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import('../views/Resources.vue')
  },
  {
    path: '/resources/:id/:slug',
    name: 'Resource Details',
    component: () => import('../views/ResourceDetails.vue')
  },
  {
    path: '/unit-library',
    name: 'Unit Library',
    component: () => import('../views/UnitLibrary.vue')
  },
  {
    path: '/unit-library/:id',
    name: 'Training Package',
    component: () => import('../views/UnitCategoryDetails.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/blog/:id',
    name: 'Blog Details',
    component: () => import('../views/BlogDetails.vue')
  },
  {
    path: '/roi-calculator',
    name: 'ROI Calculator',
    component: () => import('../views/ROICalculator.vue')
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/*',
    name: 'Not Found',
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => {
    // if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  try {
    document.body.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  } catch (ex) {

  }

  next()
})

export default router
