








































import { Mixins, Vue, Component } from 'vue-property-decorator'
import ContentContainer from '../layout/ContentContainer.vue'
import Dots from '../design/Dots.vue'
import ImageCard from '../cards/ImageCard.vue'

@Component({
  components: {
    ContentContainer,
    Dots,
    ImageCard
  }
})
export default class Masthead extends Mixins(Vue) {}
