










import { Mixins, Vue, Component } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class SubFooter extends Mixins(Vue) {
  get year (): string {
    return new Date().getFullYear().toString()
  }
}
