









import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'
import BaseCard from './BaseCard.vue'

@Component({
  components: {
    BaseCard
  }
})
export default class LinkCard extends Mixins(Vue) {
  @Prop({ default: true })
  private readonly hasBoxShadow!: boolean

  @Prop({})
  private readonly hideOverflow!: boolean

  @Prop({ default: false })
  private readonly external!: boolean

  @Prop({ required: true })
  private readonly to!: string
}
