









import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'
import Header from './components/common/Header.vue'
import Footer from './components/common/Footer.vue'
import SubFooter from './components/common/SubFooter.vue'

@Component({
  components: {
    Header,
    Footer,
    SubFooter
  }
})
export default class App extends Mixins(Vue) {
  @Prop()
  private readonly errored!: boolean;
}
